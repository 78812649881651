var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Xin nghỉ: " + _vm.dataRow.createdBy,
            visible: _vm.dialogVisible,
            width: "40%",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "table-content row-data" },
            [
              _c(
                "el-table",
                {
                  staticClass: "table-icon-1",
                  attrs: {
                    data: _vm.dayLeaveList,
                    "highlight-current-row": "",
                    "header-cell-style": _vm.tableHeaderColor,
                    "max-height": _vm.$tableMaxHeight,
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      sortable: "",
                      label: "STT",
                      width: "70",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Ngày nghỉ", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("formatDate")(scope.row.date))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Buổi sáng", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.morning
                              ? _c("i", {
                                  staticClass: "el-icon-circle-check",
                                  staticStyle: {
                                    "margin-left": "10px",
                                    color: "#409eff",
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Buổi chiều", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.afternoon
                              ? _c("i", {
                                  staticClass: "el-icon-circle-check",
                                  staticStyle: {
                                    "margin-left": "10px",
                                    color: "#409eff",
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Buổi tối", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.evening
                              ? _c("i", {
                                  staticClass: "el-icon-circle-check",
                                  staticStyle: {
                                    "margin-left": "10px",
                                    color: "#409eff",
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }